<template>
    <div class="accredit">
        <img :src="icon"/>
        <div>{{accreditText}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            icon:'',
            accreditText:'',
        }
    },
    created(){
        this.icon = require('@/assets/image/succ.png')
        this.accreditText = '授权成功'
    }
}
</script>

<style lang="scss" scoped>
.accredit{
    width:100%;
    text-align:center;
    padding:140px 0 0 0;
    div{
        margin: 10px 0 0 0;
        font-size: 16px;
    }
}
</style>